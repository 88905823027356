export const department = [
  { value: "ACC", label: "ACCOUNTING" },
  { value: "AFR", label: "AFRICANA STUDIES" },
  { value: "ASL", label: "AMERICAN SIGN LANGUAGE" },
  { value: "AMS", label: "AMERICAN STUDIES" },
  { value: "ANT", label: "ANTHROPOLOGY" },
  { value: "ARA", label: "ARABIC" },
  { value: "ARC", label: "ARCHITECTURAL STUDIES" },
  { value: "ART", label: "ART" },
  { value: "AHI", label: "ART HISTORY" },
  { value: "AT", label: "ART & TECHNOLOGY" },
  { value: "AST", label: "ASTRONOMY" },
  { value: "BIO", label: "BIOLOGY" },
  { value: "BOT", label: "BOTANY" },
  { value: "CIP", label: "CAREER SERCIVES INTERNSHIP" },
  { value: "CHM", label: "CHEMISTRY" },
  { value: "CHI", label: "CHINESE" },
  { value: "CLA", label: "CLASSICS" },
  { value: "COM", label: "COMPUTER SCIENCE" },
  { value: "CRE", label: "CRITICAL RACE & ETHNICITY" },
  { value: "DAN", label: "DANCE" },
  { value: "EAS", label: "EAST ASIAN STUDIES" },
  { value: "ECO", label: "ECONOMICS" },
  { value: "EDU", label: "EDUCATION" },
  { value: "ENG", label: "ENGLISH" },
  { value: "ES", label: "ENVIRONEMNTAL STUDIES" },
  { value: "FLM", label: "FILM STUDIES" },
  { value: "FRH", label: "FRENCH" },
  { value: "GWS", label: "GENDER, SEXUALITY, INTERSCT STDY" },
  { value: "GEO", label: "GEOSCIENCE" },
  { value: "GER", label: "GERMAN STUDIES" },
  { value: "GIS", label: "GLOBAL ISLAMIC STUDIES" },
  { value: "GOV", label: "GOVERNMENT" },
  { value: "GRK", label: "GREEK" },
  { value: "HBR", label: "HEBREW" },
  { value: "SPA", label: "HISPANIC STUDIES" },
  { value: "HIS", label: "HISTORY" },
  { value: "HMD", label: "HUMAN DEVELOPMENT" },
  { value: "IS", label: "INTERNATIONAL STUDIES" },
  { value: "CRT", label: "IP: CREATIVITY" },
  { value: "DAT", label: "IP: DATA, INFORMATION, SOCIETY" },
  { value: "ENT", label: "IP: ENTREPRENEURSHIP" },
  { value: "FDP", label: "IP: FOOD" },
  { value: "GC", label: "IP: GLOBAL CAPITALISM" },
  { value: "MRC", label: "IP: MEDIA, RHETORIC, COMMUNIATION" },
  { value: "PAX", label: "IP: PEACE AND CONFLICT" },
  { value: "PKP", label: "IP: POWER KNOWLEDGE & PARCTIVE" },
  { value: "PBH", label: "IP: PUBLIC HEALTH" },
  { value: "SJS", label: "IP: SOCIAL JUSTICE/SUSTAINABIL" },
  { value: "ITL", label: "ITALIAN" },
  { value: "JPN", label: "JAPNAESE" },
  { value: "JS:", label: "JEWISH STUDIES" },
  { value: "LAT", label: "LATIN" },
  { value: "LA:", label: "LIBERAL ARTS" },
  { value: "LIN", label: "LINGUISTICS" },
  { value: "MAT", label: "MATHEMATICS" },
  { value: "MSM", label: "MUSEUM STUDIES" },
  { value: "MUS", label: "MUSIC" },
  { value: "NEU", label: "NEUROSCIENCE" },
  { value: "PHI", label: "PHILOSOPHY" },
  { value: "PHE", label: "PHYSICAL EDUCATION" },
  { value: "PHY", label: "PHYSICS" },
  { value: "PSY", label: "PSYCHOLOGY" },
  { value: "RUS", label: "RUSSIAN" },
  { value: "SLA", label: "SLAVIC STUDIES" },
  { value: "SOC", label: "SOCIOLOGY" },
  { value: "STA", label: "STATISTICS" },
  { value: "THE", label: "THEATER" },
];

export const majors = [
  "ACS Certified - Chemistry",
  "ACS Certified- Chem/Biochem",
  "Africana Studies",
  "American Studies",
  "Anthropology",
  "Architectural Studies",
  "Art",
  "Art History",
  "Behavioral Neuroscience",
  "Biological Sciences",
  "Botany",
  "Classics",
  "Computer Science",
  "Dance",
  "East Asian Studies",
  "Economics",
  "Educational Studies",
  "English",
  "Environmental Chemistry",
  "Environmental Engineering Studies",
  "Environmental Studies",
  "Film Studies",
  "French",
  "Gendr, Sexualty, Intersction Stds",
  "German Studies",
  "Global Islamic Studies",
  "Government",
  "Hispanic Studies",
  "History",
  "Human Development",
  "International Relations",
  "Italian Studies",
  "Latin American and Latino Studies",
  "Mathematics",
  "Music",
  "Music and Technology",
  "Music Studies",
  "Music, Sound, and Technology",
  "Neuroscience",
  "Philosophy",
  "Physics",
  "Psychology",
  "Quantitative Economics and Econometrics",
  "Religious Studies",
  "Slavic Studies",
  "Statistics and Data Science",
  "Student-Designed Interdscplnry",
  "Theater",
];

export const majorAdvisors = {
  "Computer Science": [
    "Ozgur Izmirli",
    "Christine Chung",
    "Stephen Douglass",
    "S. James Lee",
    "Gary Parker",
    "William Tarimo",
  ],
  Mathematics: [
    "Christopher Hammond",
    "Priya Kohli",
    "Warren P. Johnson",
    "Augustine B. O'Keefe",
    "Yan Zhuang",
  ],
  "Statistics and Data Science": [
    "Christopher Hammond",
    "Priya Kohli",
    "Warren P. Johnson",
    "Augustine B. O'Keefe",
    "Yan Zhuang",
  ],
};

export const majorInit = {
  "Computer Science": ["COM"],
  Mathematics: ["MAT", "STA"],
  "Statistics and Data Science": ["MAT", "STA"],
};

export const majorRequirementCourses = {
  "Computer Science": {
    "core courses": [
      "MAT210",
      "COM110",
      "COM212",
      "COM219",
      "COM304",
      "COM315",
      "COM326",
      "COM496",
      "COM495",
      "COM499",
    ],
    electives200: 3,
    electives300: 2,
    total: 14,
  },
  Mathematics: {
    "core courses": [
      "MAT210",
      "MAT212",
      "MAT226",
      "MAT301",
      "MAT303",
      "MAT495",
      "MAT497",
      "MAT498",
      "MAT309",
      "MAT402",
      "MAT404",
      "STA317",
    ],
    electives200: 1,
    electives300: 2,
    total: 11,
  },
};

export const majorCourses = {
  "Computer Science": {
    COM102: "Introductory Informatics",
    COM103: "CC: Introductory Informatics",
    COM110: "CC: Intro Comp Sci & Prob Solv",
    COM110L: "Intro Comp Sci & Prob Solv Lab",
    COM203: "Bioinformatics",
    COM205: "Topics In Software Development",
    COM211: "Informatics",
    COM212: "Data Structures",
    COM213: "Electronics for Scientists",
    COM214: "Web Technologies & Development",
    COM217: "Entertainment Software Design",
    COM218: "Multimedia",
    COM219: "Computer Organization",
    COM250: "Mobile App Development",
    COM291: "Individual Study",
    COM292: "Individual Study",
    COM302: "Object-Oriented Software Design",
    COM303: "Database Systems",
    COM304: "Algorithm",
    COM305: "Topics In Software Development",
    COM306: "Software Engineering",
    COM307: "Machine Learn/Data Mining",
    COM309: "Graphics & Virtual Environments",
    COM310: "Robotics",
    COM312: "Digital Sound Processing",
    COM313: "Algorithmic Game Theory",
    COM315: "Computer Networks",
    COM316: "Artificial Intelligence",
    COM320: "Programming Languages",
    COM322: "Computer Vision",
    COM323: "Theory of Computation",
    COM325: "Intro to Cybersecurity",
    COM326: "Operating Systems",
    COM391: "Individual Study",
    COM392: "Individual Study",
    COM407: "Computational Intelligence",
    COM491: "Individual Study",
    COM492: "Individual Study",
    COM495: "Research Seminar",
    COM496: "Research Seminar",
    COM497: "Honors Study",
    COM498: "Honors Study",
    COM499: "Computer Science Colloquium",
  },
};
